import 'assets/css/loading.css';

const Loading = ({ className }) => {
  return (
    <div>
      <div className={`lds-ring ${className}`}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
