import ReactPaginate from 'react-paginate';
import { ICON_PAGINATE_LEFT, ICON_PAGINATE_RIGHT } from 'constant/Icon';
const Pagination = ({ page, pageCount, handlePageClick }) => {
  return (
    <ReactPaginate
      initialPage={page !== 1 ? page - 1 : undefined}
      breakLabel='...'
      nextLabel={<ICON_PAGINATE_RIGHT />}
      onPageChange={handlePageClick}
      pageCount={pageCount}
      marginPagesDisplayed={1}
      pageRangeDisplayed={2}
      previousLabel={<ICON_PAGINATE_LEFT />}
      renderOnZeroPageCount={null}
      containerClassName={'h-fit flex items-center gap-x-3'}
      pageClassName={
        'w-[28px] h-[40px] flex justify-center items-center text-[#717171] hover:text-primary rounded-sm transition-colors duration-100'
      }
      pageLinkClassName={'px-2 py-2 select-none'}
      previousClassName={`${
        page === 1 ? 'opacity-0' : 'opacity-1'
      } w-[28px] h-[40px] flex justify-center items-center border border-gray-300 rounded-sm hover:text-white hover:bg-red-100 hover:border-red-100 transition-colors duration-200`}
      previousLinkClassName={'block px-2 py-2'}
      nextClassName={`${
        page === pageCount ? 'opacity-0' : 'opacity-1'
      } w-[28px] h-[40px] flex justify-center items-center border border-gray-300 rounded-sm hover:text-white hover:bg-red-100 hover:border-red-100 transition-colors duration-200`}
      nextLinkClassName={'block px-2 py-2'}
      breakClassName={
        'text-[#717171] hover:text-primary rounded-sm w-[28px] h-[40px] flex justify-center items-center transition-colors duration-100 select-none'
      }
      breakLinkClassName={'block px-2 py-2'}
      activeClassName={'text-primary bg-red-100'}
    />
  );
};

export default Pagination;
