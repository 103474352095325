import Image from 'components/utils/Image';
import React from 'react';

export default function DigitalMuseumCard({ href, img, title, imageWidth, imageHeight }) {
  return (
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      className='gap-x-4 cursor-pointer hover:text-primary transition-colors duration-100'
    >
      <div className='w-full gap-x-4 h-fit shadow-md rounded-md overflow-hidden'>
        <div className={`w-full h-fit`}>
          <Image
            alt=''
            src={img}
            containerClassName={`after:pt-[66.5%]`}
            imgClassName={`object-cover aspect-[3/2]`}
            width={imageWidth}
            height={imageHeight}
          />
        </div>
        <div className={`relative font-bold h-[65px] max-h-[65px] text-center mt-[-8px] py-3`}>
          <p className='absolute w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 line-clamp-2 px-4'>{title}</p>
        </div>
      </div>
    </a>
  );
}
