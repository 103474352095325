import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { PlayCircleIcon, PauseCircleIcon } from '@heroicons/react/24/outline';
import Loading from '../Loading';

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#D9D9D9',
  progressColor: '#B7070A',
  cursorColor: '#B7070A',
  barWidth: 3,
  barRadius: 3,
  responsive: true,
  height: 8,
  normalize: true,
  partialRender: true,
  renderFunction: (channels, ctx) => {
    const { width, height } = ctx.canvas;
    const scale = channels[0].length / width;
    const step = 10;

    ctx.translate(0, height / 2);
    ctx.strokeStyle = ctx.fillStyle;
    ctx.beginPath();
    ctx.lineWidth = 8;

    for (let i = 0; i < width; i += step * 2) {
      const index = Math.floor(i * scale);
      const value = Math.abs(channels[0][index]);
      let x = i;
      let y = value * height;

      ctx.moveTo(x, 0);
      ctx.lineTo(x + step, 0);

      x = x + step;
      y = -y;
      ctx.moveTo(x, 0);
      ctx.lineTo(x + step, 0);
    }

    ctx.stroke();
    ctx.closePath();
  }
});

export default function Audio({ audioSource, autoPlay }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [playing, setPlay] = useState(autoPlay);
  const [volume, setVolume] = useState(0.5);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setPlay(false);

    const options = formWaveSurferOptions(waveformRef.current);
    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(audioSource);

    wavesurfer.current.on('ready', function () {
      setDuration(wavesurfer.current.getDuration());

      // make sure object still available when file loaded
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);
        setVolume(volume);
      }
    });

    wavesurfer.current.on('audioprocess', function () {
      setCurrentTime(wavesurfer.current.getCurrentTime());
    });
    wavesurfer.current.on('finish', function () {
      setPlay(false);
    });
    return () => wavesurfer.current.destroy();
  }, [audioSource]);

  useEffect(() => {
    if (duration > 0) {
      setLoading(false);
    }
  }, [duration]);
  const handlePlayPause = (e) => {
    if (isLoading) return;
    e.preventDefault();
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const onVolumeChange = (e) => {
    const { target } = e;
    const newVolume = +target.value;

    if (newVolume) {
      setVolume(newVolume);
      wavesurfer.current.setVolume(newVolume || 1);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className='bg-[#ECECEC] pl-3 pr-5 lg:pl-6 lg:pr-8 py-1 lg:py-2 rounded-full'>
      <div className='flex items-center gap-x-4'>
        <div className='flex items-center gap-x-2'>
          {isLoading ? (
            <div className='w-10 h-10'>
              <Loading className='mt-[-1px]' />
            </div>
          ) : (
            <div
              className='w-10 h-10 flex items-center justify-center text-primary cursor-pointer transition-colors duration-100 rounded-full hover:bg-black hover:bg-opacity-10'
              onClick={handlePlayPause}
            >
              {!playing || currentTime === duration ? (
                <PlayCircleIcon className='w-8 h-8' />
              ) : (
                <PauseCircleIcon className='w-8 h-8' />
              )}
            </div>
          )}
          <div className='flex gap-x-1 font-bold h-[21px] text-sm lg:text-base'>
            <span>{formatTime(currentTime)}</span>
            <span>/</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
        <div
          className='data-[state=hidden]:hidden data-[state=show]:block animate-loading w-full'
          data-state={isLoading ? 'show' : 'hidden'}
        >
          <div className='animate-pulse w-full'>
            <div className='w-full h-2 rounded-full overflow-hidden bg-[#D9D9D9]'></div>
          </div>
        </div>
        <div
          className='data-[state=hidden]:hidden data-[state=show]:block w-full'
          data-state={isLoading ? 'hidden' : 'show'}
        >
          <div className='w-full h-2 cursor-pointer rounded-full overflow-hidden' ref={waveformRef} />
        </div>
      </div>
      <div className='time-info'></div>
    </div>
  );
}
