import { ICON_IMAGE_PLACEHOLDER } from 'constant/Icon';

const DigitalLibraryDetailsLoading = ({ children, isLoading }) => {
  return (
    <div>
      <div
        className='data-[state=hidden]:hidden data-[state=show]:block animate-loading'
        data-state={isLoading ? 'show' : 'hidden'}
      >
        <div className='animate-pulse'>
          <div className='block sm:flex gap-x-8 h-fit cursor-default mb-4'>
            <div className='w-full sm:w-2/5 xl:w-1/5 mb-4 sm:mb-0'>
              <div class='inline-block w-full h-fit relative after:block after:pt-[133.33%]'>
                <div className='absolute top-0 left-0 right-0 bottom-0 rounded-2xl flex items-center justify-center bg-gray-400'>
                  <ICON_IMAGE_PLACEHOLDER fill={'#e5e7eb'} />
                </div>
              </div>
            </div>
            <div className='w-full sm:w-3/5 xl:w-4/5'>
              <div className='grid grid-cols-1 h-[84px] xl:h-[56px] mb-4'>
                <div className='bg-gray-400 rounded-md h-6 w-full'></div>
                <div className='bg-gray-400 rounded-md h-6 w-full block xl:hidden'></div>
                <div className='bg-gray-400 rounded-md h-6 w-8/12'></div>
              </div>
              {Array.from(Array(7), (index) => {
                return (
                  <div className='flex gap-x-4 mb-3'>
                    <div className='bg-gray-400 rounded-md h-5 w-[150px]'></div>
                    <div className={`bg-gray-400 rounded-md h-5 ${index % 2 === 0 ? 'w-[250px]' : 'w-[300px]'}`}></div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className='bg-gray-400 rounded-md h-6 w-full mb-2'></div>
          <div className='bg-gray-400 rounded-md h-6 w-full mb-2'></div>
          <div className='bg-gray-400 rounded-md h-6 w-full mb-2'></div>
          <div className='bg-gray-400 rounded-md h-6 w-full mb-2'></div>
          <div className='bg-gray-400 rounded-md h-6 w-8/12'></div>
          <div className='flex justify-center mt-8 mb-16'>
            <div className='w-[600px] h-[400px] xl:w-[800px] xl:h-[600px] bg-gray-400 rounded-md'></div>
          </div>
        </div>
      </div>
      <div
        className='data-[state=hidden]:hidden data-[state=show]:block animate-loading'
        data-state={isLoading ? 'hidden' : 'show'}
      >
        {children}
      </div>
    </div>
  );
};

export default DigitalLibraryDetailsLoading;
