import { Suspense, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Audio from 'components/utils/Audio/Audio';
import Image from 'components/utils/Image';
import { LOAI_THU_VIEN } from 'data/enum';
import axiosDigitalLibraryInstance from 'helpers/axios-digital-library';
import DigitalLibraryDetailsLoading from 'components/layout/loading/DigitalLibraryDetailsLoading';
import { ICON_HEART, ICON_PDF, ICON_HEART_FILL } from 'constant/Icon';
import DigitalLibraryVerticalCard from 'components/digital-library/DigitalLibraryVerticalCard';
import formatTime from 'helpers/formatTime';
import useToast from 'hooks/useToast';

const FileInfoItem = ({ label, value }) =>
  !!value && (
    <div className='flex items-start gap-x-4 mb-2'>
      <p className='min-w-[120px] lg:min-w-[140px] font-bold'>{label}</p>
      <p>{value || ''}</p>
    </div>
  );

const DigitalLibraryFileDetails = () => {
  const { defaultBreadcrumbs, breadcrumbs, setBreadcrumbs, loadingBreadcrumbs, setLoadingBreadcrumbs } =
    useOutletContext();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [digitalLibraryFile, setDigitalLibraryFile] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [filesCanYouCare, setFilesCanYouCare] = useState([]);
  const navigate = useNavigate();
  const { toast } = useToast();

  const shuffleArray = (array) => {
    let shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const fetchData = async () => {
    setLoading(true);
    setLoadingBreadcrumbs(true);
    let response = null;
    let filesCanYouCare = [];
    if (params['loai_thu_vien'] === LOAI_THU_VIEN.THU_VIEN_CHUNG) {
      const token = localStorage.getItem('accessToken');
      const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
      response = await axiosDigitalLibraryInstance.get(
        `/public/digital-library-file/${params['file_id']}?isViewer=true`,
        {
          ...config
        }
      );
      filesCanYouCare = await axiosDigitalLibraryInstance.get(
        `/public/digital-library-file/folder/${params['folder_id']}`,
        {
          params: { page: 1, limit: 10 }
        }
      );
    } else if (params['loai_thu_vien'] === LOAI_THU_VIEN.THU_VIEN_DON_VI) {
      const token = localStorage.getItem('accessToken');
      const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
      response = await axiosDigitalLibraryInstance.get(`/digital-library-file/${params['file_id']}?isViewer=true`, {
        ...config
      });
      filesCanYouCare = await axiosDigitalLibraryInstance.get(`/digital-library-file/folder/${params['folder_id']}`, {
        ...config,
        params: { page: 1, limit: 10 }
      });
    }
    const shuffleFilesCanYouCare = shuffleArray(filesCanYouCare.data.data)
      .filter((item) => item.id !== params['file_id'])
      .slice(0, 4);
    setFilesCanYouCare(shuffleFilesCanYouCare);
    const isFolderDisplay = response.data.folder.isDisplay;
    if (!isFolderDisplay) {
      navigate(`/thu-vien-so/${params['loai_thu_vien']}`);
      toast({
        isOpen: true,
        error: true,
        message: 'Thư mục của bài viết này đã bị ẩn'
      });
      setLoadingBreadcrumbs(false);
      return;
    }
    setLikeCount(response.data.favoriteCount);
    setIsLiked(response.data.isFavorite);
    const fileBreadcrumbs = {
      title: response.data.name,
      href: `/thu-vien-so/${params['loai_thu_vien']}/${params['folder_id']}/${response.data.id}`
    };
    const folderBreadcrumbs = response.data.breadcrumbs.map((breadcrumb) => ({
      ...breadcrumb,
      title: breadcrumb.name,
      href: `/thu-vien-so/${params['loai_thu_vien']}/${breadcrumb.id}`
    }));
    let libraryTypeBreadcrumb;
    if (params['loai_thu_vien'] === LOAI_THU_VIEN.THU_VIEN_CHUNG) {
      libraryTypeBreadcrumb = {
        id: LOAI_THU_VIEN.THU_VIEN_CHUNG,
        title: 'Thư viện chung',
        href: `/thu-vien-so/${LOAI_THU_VIEN.THU_VIEN_CHUNG}`
      };
    } else if (params['loai_thu_vien'] === LOAI_THU_VIEN.THU_VIEN_DON_VI) {
      libraryTypeBreadcrumb = {
        id: LOAI_THU_VIEN.THU_VIEN_DON_VI,
        title: 'Thư viện đơn vị',
        href: `/thu-vien-so/${LOAI_THU_VIEN.THU_VIEN_DON_VI}`
      };
    }
    setBreadcrumbs([...defaultBreadcrumbs, libraryTypeBreadcrumb, ...folderBreadcrumbs, fileBreadcrumbs]);
    setDigitalLibraryFile(response.data);
    setLoading(false);
    setLoadingBreadcrumbs(false);
  };

  useEffect(() => {
    fetchData();
  }, [params['file_id']]);

  const renderFile = () => {
    switch (digitalLibraryFile.fileType) {
      case 'document':
        return (
          <iframe
            title={digitalLibraryFile.fileDocument.pdfFile.fileName}
            src={`${digitalLibraryFile.fileDocument.pdfFile.link}?alt=media`}
            className='w-full'
            height='600'
          ></iframe>
        );
      case 'audio-book':
        return (
          <div className='w-full'>
            <Audio audioSource={`${digitalLibraryFile.fileAudioBook.audioFile.link}?alt=media`}></Audio>
          </div>
        );
      case 'video':
        return (
          <div className='w-full bg-black flex justify-center'>
            <video title={digitalLibraryFile.fileVideo.videoFile.fileName} controls>
              <source src={`${digitalLibraryFile.fileVideo.videoFile.link}?alt=media`}></source>
            </video>
          </div>
        );
      case 'viewer':
        return (
          <iframe
            title={digitalLibraryFile?.fileViewer?.fileName}
            src={`${digitalLibraryFile.fileViewer.link}?alt=media`}
            className='w-full'
            height='600'
          ></iframe>
        );
      default:
        break;
    }
  };

  const handleDownloadPDF = async () => {
    let fileName = null;
    let fileUrl = null;
    switch (digitalLibraryFile.fileType) {
      case 'document':
        fileUrl = digitalLibraryFile.fileDocument.pdfFile.link;
        fileName = digitalLibraryFile.fileDocument.pdfFile.fileName;
        break;
      case 'audio-book':
        fileUrl = digitalLibraryFile.fileAudioBook.audioFile.link;
        fileName = digitalLibraryFile.fileAudioBook.audioFile.fileName;
        break;
      case 'video':
        fileUrl = digitalLibraryFile.fileVideo.videoFile.link;
        fileName = digitalLibraryFile.fileVideo.videoFile.fileName;
        break;
      case 'viewer':
        fileUrl = digitalLibraryFile.fileViewer.link;
        fileName = digitalLibraryFile.fileViewer.fileName;
        break;
      default:
        break;
    }
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'tailieu.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);

    await axiosDigitalLibraryInstance.put(`/public/digital-library-file/download-count/${digitalLibraryFile.id}`);
  };

  const handleLike = async () => {
    setIsLiked((prev) => !prev);
    setLikeCount((prev) => (isLiked ? prev - 1 : prev + 1));
    const token = localStorage.getItem('accessToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    await axiosDigitalLibraryInstance.post(
      `/digital-library-file/favorite`,
      {
        favoriteId: digitalLibraryFile.id
      },
      {
        ...config
      }
    );
  };

  return (
    <div>
      <DigitalLibraryDetailsLoading isLoading={loading}>
        {!!digitalLibraryFile && (
          <div>
            <div className='block sm:flex gap-x-8 h-fit cursor-default mb-4'>
              <div className='w-full sm:w-2/5 xl:w-1/5 mb-4 sm:mb-0'>
                <Image
                  alt=''
                  src={digitalLibraryFile.coverPhoto}
                  containerClassName={`after:pt-[133.33%] rounded-xl cursor-pointer`}
                  imgClassName={`h-full w-full object-cover aspect-[3/4] rounded-xl`}
                  width={500}
                  height={667}
                />
              </div>
              <div className='w-full sm:w-3/5 xl:w-4/5'>
                <div className='flex items-center justify-between gap-x-10'>
                  <p
                    title={digitalLibraryFile.name || ''}
                    className={`font-semibold line-clamp-3 text-xl text-primary transition-colors duration-100 mb-4 cursor-pointer w-10/12`}
                  >
                    {digitalLibraryFile.name || ''}
                  </p>
                  <div className='flex items-center gap-x-2 w-2/12 cursor-pointer'>
                    <div onClick={handleLike}>{isLiked ? <ICON_HEART_FILL /> : <ICON_HEART />}</div>
                    <p className='text-[#7E7E7E]'>{likeCount || 0}</p>
                  </div>
                </div>
                <FileInfoItem label='Tác giả:' value={digitalLibraryFile.author} />
                <FileInfoItem label='Nhà cung cấp:' value={digitalLibraryFile.supplier} />
                <FileInfoItem label='Nhà xuất bản:' value={digitalLibraryFile.publishingHouse} />
                <FileInfoItem label='Năm xuất bản:' value={digitalLibraryFile.publishedYear} />
                <FileInfoItem label='Danh mục:' value={digitalLibraryFile?.category?.name} />
                <FileInfoItem label='Số trang:' value={digitalLibraryFile?.fileDocument?.totalPage} />
                <FileInfoItem label='Khổ cỡ:' value={digitalLibraryFile?.fileDocument?.size} />
                {!!digitalLibraryFile?.fileVideo?.duration && (
                  <FileInfoItem label='Thời lượng:' value={formatTime(digitalLibraryFile?.fileVideo?.duration)} />
                )}
                {digitalLibraryFile.fileType === 'document' && (
                  <div className='flex flex-col xl:flex-row gap-x-4 gap-y-2'>
                    <button
                      type='submit'
                      className='flex items-center gap-x-2 px-4 py-2 border rounded-full bg-gradient-to-r from-[#E84341] from-[100%] to-[#C82220] to-107.42% text-white transition-colors duration-100 whitespace-nowrap'
                      onClick={handleDownloadPDF}
                    >
                      <ICON_PDF />
                      Xuất tài liệu (PDF)
                    </button>
                    {digitalLibraryFile?.fileDocument?.audioFile?.link && (
                      <div className='w-full'>
                        <Audio audioSource={`${digitalLibraryFile.fileDocument.audioFile.link}?alt=media`} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <p>
              <span className='font-bold'>Mô tả: </span>
              <p style={{ whiteSpace: 'pre-line' }}>{digitalLibraryFile.description}</p>
            </p>
            <div className='flex justify-center mt-8 mb-16'>
              {renderFile(digitalLibraryFile.fileType, digitalLibraryFile)}
            </div>
          </div>
        )}
      </DigitalLibraryDetailsLoading>
      <div>
        <div className='p-1 px-4 border-l-[3px] border-primary mb-6 lg:mb-12'>
          <h5 className='font-bold text-xl'>CÓ THỂ BẠN QUAN TÂM</h5>
        </div>
        <Suspense fallback={null}>
          <div className='w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 sm:gap-x-8 gap-y-6'>
            {!!filesCanYouCare?.length &&
              filesCanYouCare?.map((digitalLibraryFile) => (
                <div className='w-full'>
                  <DigitalLibraryVerticalCard
                    href={`/thu-vien-so/${params['loai_thu_vien']}/${params['folder_id']}/${digitalLibraryFile.id}`}
                    title={digitalLibraryFile.name}
                    updatedAt={digitalLibraryFile.updatedAt}
                    img={digitalLibraryFile.coverPhoto}
                    content={digitalLibraryFile.description}
                    viewCount={digitalLibraryFile?.viewCount || 0}
                    downloadCount={digitalLibraryFile?.downloadCount || 0}
                    likeCount={digitalLibraryFile?.favoriteCount || 0}
                  />
                </div>
              ))}
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default DigitalLibraryFileDetails;
