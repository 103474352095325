import { useState, useEffect } from 'react';
import axiosInstance from 'helpers/axios';
import axiosDigitalLibraryInstance from 'helpers/axios-digital-library';

const useAxios = (url, options, isDigitalLibrary = false, requireAuth = false) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (url) {
        try {
          setLoading(true);
          const axios = isDigitalLibrary ? axiosDigitalLibraryInstance : axiosInstance;
          const token = localStorage.getItem('accessToken');
          if (requireAuth && !token) return;
          const config =
            token && requireAuth
              ? { headers: { Authorization: `Bearer ${token}` }, params: { ...options } }
              : { params: { ...options } };
          const response = await axios.get(url, config);
          setData(response.data);
          if (response.data.status === 'FAILED') {
            setError(response.data.message);
          }
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [url, options]);

  return { data, loading, error };
};

export default useAxios;
