function checkZero(data) {
  if (data.length === 1) {
    data = '0' + data;
  }
  return data;
}

const dayNames = ['Chủ nhật', 'Thứ hai', 'Thứ ba', 'Thứ tư', 'Thứ năm', 'Thứ sáu', 'Thứ bảy'];

export function formatDate(dateString, short = false) {
  let date = new Date(dateString?.slice(0, -1));
  date.setHours(date.getHours() + 7);
  let day = date.getDate() + '';
  let month = date.getMonth() + 1 + '';
  let year = date.getFullYear() + '';
  let hour = date.getHours() + '';
  let minutes = date.getMinutes() + '';
  const dayOfWeekNumber = date.getDay();

  day = checkZero(day);
  month = checkZero(month);
  year = checkZero(year);
  hour = checkZero(hour);
  minutes = checkZero(minutes);

  if (short) return `${day}/${month}/${year} ${hour}:${minutes}`;
  else return `${dayNames[dayOfWeekNumber]}, ${day}/${month}/${year} ${hour}:${minutes}`;
}

export function formatDateVietnamese(dateString) {
  const date = new Date(dateString);
  date.setHours(date.getHours() + 7);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}
