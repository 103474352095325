const Image = ({ isLoading, containerClassName, imgClassName, src, alt, width, height }) => {
  return (
    <div
      className={`bg-cover bg-no-repeat bg-image-placeholder inline-block w-full h-fit relative after:block ${containerClassName}`}
    >
      <div
        className='absolute top-0 left-0 right-0 bottom-0 data-[state=hidden]:opacity-0 data-[state=show]:opacity-100 duration-200 h-fit'
        data-state={isLoading ? 'hidden' : 'show'}
      >
        {!!src && (
          <img
            className={`w-full ${imgClassName}`}
            src={`${
              src.includes('http://') ? `${process.env.REACT_APP_BACK_END_IMAGE_PROXY}${src}` : src
            }?alt=media&width=${width}${height ? `&height=${height}` : ''}&target_type=webp`}
            alt={alt}
          />
        )}
      </div>
    </div>
  );
};

export default Image;
