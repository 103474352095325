import { useToastContext } from 'context/toast.context';

export default function useToast() {
  const { setToastContent } = useToastContext();
  const toast = (toastContent) => {
    setToastContent(toastContent);
    setTimeout(() => {
      setToastContent((prev) => ({
        ...prev,
        isOpen: false
      }));
    }, [3000]);
  };

  return { toast };
}
