import { ICON_NEWS_SPEAKER } from 'constant/Icon';
import newIcon from 'assets/images/new_icon.png';
import { Link } from 'react-router-dom';
import useAxios from 'hooks/useAxios';
import HeroBannerSuspense from 'components/utils/HeroBannerSuspense';
import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import useDebounce from 'hooks/useDebounce';
import axiosDigitalLibraryInstance from 'helpers/axios-digital-library';
import Loading from 'components/utils/Loading';

const HeroBanner = lazy(() => import('components/news/HeroBanner'));
const NewsSection = lazy(() => import('pages/news/news-section'));

export default function News() {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const debouncedFilterValue = useDebounce(searchValue, 400);
  const [page, setPage] = useState(1);
  const totalPages = useRef(0);
  const [news, setNews] = useState([]);
  const [searched, setSearched] = useState(false);
  const { data: latestNews, loading: latestNewsLoading } = useAxios(`/public/news/latest?page=1&limit=10`, null, true);

  const fetchData = async () => {
    setLoading(true);
    const queryParams = {
      isDisplay: true,
      page: page,
      limit: 1
    };
    if (debouncedFilterValue) {
      queryParams.search = debouncedFilterValue;
    }
    const token = localStorage.getItem('accessToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    const response = await axiosDigitalLibraryInstance.get(`/public/news-category/landing-page`, {
      ...config,
      params: queryParams
    });
    totalPages.current = response.data.totalPages;
    setNews((prevItems) => (!searched ? [...prevItems, ...(response?.data?.data || [])] : response?.data?.data));
    setLoading(false);
  };

  const handleScroll = () => {
    setSearched(false);
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 350) {
      if (page < totalPages.current) setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchData();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [page, debouncedFilterValue]);

  return (
    <div className=''>
      <Suspense fallback={<HeroBannerSuspense />}>
        <HeroBanner latestNews={latestNews} latestNewsLoading={latestNewsLoading} />
      </Suspense>
      {!!latestNews?.data?.length && (
        <div className='container flex mx-auto mt-4'>
          <div className='w-[100px] bg-white'>{ICON_NEWS_SPEAKER()}</div>
          <div className='overflow-hidden mx-auto'>
            <div class='animate-marquee hover:pause h-full flex items-center gap-x-12 font-bold'>
              {latestNews.data.map((latestNew) => (
                <Link
                  className='flex items-center hover:text-primary duration-100 cursor-pointer'
                  title={latestNew.title}
                  to={`/tin-tuc/${latestNew?.newsCategory?.slug}/${latestNew?.slug}`}
                >
                  <img src={newIcon} alt='new-icon' />
                  <span className='whitespace-nowrap'>{latestNew.title}</span>
                </Link>
              ))}
            </div>
            <div class='animate-marquee2 hover:pause-none h-full flex items-center gap-x-12 font-bold'>
              {latestNews.data.map((latestNew) => (
                <Link
                  className='flex items-center hover:text-primary duration-100 cursor-pointer'
                  title={latestNew.title}
                  to={`/tin-tuc/${latestNew.newsCategory?.slug}/${latestNew?.slug}`}
                >
                  <img src={newIcon} alt='new-icon' />
                  <span className='whitespace-nowrap'>{latestNew.title}</span>
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
      <Suspense fallback={null}>
        <div className='container mx-auto mt-8'>
          {!!news?.length &&
            news.map((item) => (
              <NewsSection title={item.name} news={item.news} href={`${item.slug}`} children={item.children} />
            ))}
        </div>
        {loading && (
          <div className='w-full flex justify-center'>
            <div className='w-20 h-20'>
              <Loading className='mt-[-1px]' />
            </div>
          </div>
        )}
      </Suspense>
    </div>
  );
}
