import { ICON_IMAGE_PLACEHOLDER } from 'constant/Icon';

const DigitalLibraryFolderLoading = ({ children, isLoading }) => {
  return (
    <div>
      <div
        className='data-[state=hidden]:hidden data-[state=show]:block animate-loading'
        data-state={isLoading ? 'show' : 'hidden'}
      >
        <div className='animate-pulse'>
          <div>
            <div className='flex justify-between mb-8'>
              <div className='bg-gray-400 rounded-md h-[32px] w-[300px]'></div>
            </div>
            {Array.from(Array(6), () => {
              return (
                <div className='border-b border-[#C0C0C0] pb-6 mb-8'>
                  <div className='flex gap-x-4 lg:gap-x-8 md:flex-row'>
                    <div className='w-2/5 lg:w-1/5 h-fit'>
                      <div class='inline-block w-full h-fit relative after:block after:pt-[133.33%]'>
                        <div className='absolute top-0 left-0 right-0 bottom-0 rounded-2xl flex items-center justify-center bg-gray-400'>
                          <ICON_IMAGE_PLACEHOLDER fill={'#e5e7eb'} />
                        </div>
                      </div>
                    </div>
                    <div className='w-3/5 lg:w-4/5'>
                      <div className='grid grid-cols-1 gap-y-2 h-[56px] mb-2 lg:mb-4 2xl:mb-8'>
                        <div className='bg-gray-400 rounded-md h-full w-full'></div>
                        <div className='bg-gray-400 rounded-md h-full w-8/12'></div>
                      </div>
                      <div className='bg-gray-400 rounded-md h-6 w-6/12 mb-2 lg:mb-4'></div>
                      <div className='grid grid-cols-1 gap-y-1 h-[80px] mb-2 lg:mb-4 2xl:mb-8'>
                        <div className='bg-gray-400 rounded-md h-4 w-full'></div>
                        <div className='bg-gray-400 rounded-md h-4 w-full'></div>
                        <div className='bg-gray-400 rounded-md h-4 w-full'></div>
                        <div className='bg-gray-400 rounded-md h-4 w-72'></div>
                      </div>
                      <div className='grid grid-cols-3 w-48 gap-x-4'>
                        <div className='bg-gray-400 rounded-md h-5 w-14'></div>
                        <div className='bg-gray-400 rounded-md h-5 w-14'></div>
                        <div className='bg-gray-400 rounded-md h-5 w-14'></div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className='data-[state=hidden]:hidden data-[state=show]:block animate-loading'
        data-state={isLoading ? 'hidden' : 'show'}
      >
        {children}
      </div>
    </div>
  );
};

export default DigitalLibraryFolderLoading;
