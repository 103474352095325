import { useNavigate, useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';
import { ICON_EXPAND } from 'constant/Icon';
import digitalLibraryLikedIcon from 'assets/images/digtal-library-liked.png';

export default function DigitalLibraryLiked() {
  const { defaultBreadcrumbs, breadcrumbs, setBreadcrumbs, loadingBreadcrumbs, setLoadingBreadcrumbs } =
    useOutletContext();
  const navigate = useNavigate();
  const fetchData = async () => {
    setLoadingBreadcrumbs(true);
    setBreadcrumbs([...defaultBreadcrumbs, { title: 'Danh sách yêu thích', href: `/thu-vien-so/danh-sach-yeu-thich` }]);
    setLoadingBreadcrumbs(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div
        className='flex items-center justify-between shadow-md px-4 py-6 rounded-lg cursor-pointer'
        onClick={() => navigate(`/thu-vien-so/danh-sach-yeu-thich/thu-vien-so`)}
      >
        <div className='flex items-center gap-x-4'>
          <div>
            <img src={digitalLibraryLikedIcon} alt='digital-library' />
          </div>
          <p className='pt-4'>Thư viện số yêu thích</p>
        </div>
        <ICON_EXPAND />
      </div>
    </div>
  );
}
