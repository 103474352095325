import axios from 'axios';

const axiosDigitalLibraryInstance = axios.create({
  baseURL: process.env.REACT_APP_DIGITAL_LIBRARY_URL
});

axiosDigitalLibraryInstance.defaults.timeout = 50000;
axiosDigitalLibraryInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('accessToken');
      window.location.href = `${process.env.REACT_APP_FRONT_END_URL}/sinhhd/login`;
    }
  }
);

const tryRefreshToken = async () => {
  try {
    const domain = process.env.REACT_APP_DIGITAL_LIBRARY_URL || '';
    const refreshUrl = domain + '/auth/refresh';
    const response = await axios.get(refreshUrl, {
      withCredentials: true
    });
    const accessToken = response?.data?.accessToken;
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      return accessToken;
    }
  } catch (error) {
    localStorage.removeItem('accessToken');
    window.location.href = `${process.env.REACT_APP_FRONT_END_URL}/sinhhd/login`;
  }
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error?.response?.status === 401) {
      try {
        const accessToken = await tryRefreshToken();
        const newHeaders = {
          ...originalRequest.headers,
          authorization: `Bearer ${accessToken}`
        };
        if (accessToken) {
          return axios({ ...originalRequest, headers: newHeaders });
        }
      } catch (refreshError) {
        localStorage.removeItem('accessToken');
        window.location.href = `${process.env.REACT_APP_FRONT_END_URL}/sinhhd/login`;
      }
    }

    return Promise.reject(error);
  }
);

export default axiosDigitalLibraryInstance;
