import { Routes, Route } from 'react-router-dom';
import { routes } from 'routes';
import ScrollToTop from 'components/utils/ScrollToTop';
import { NewShareModalContextProvider } from 'context/new-share-modal.context';
import { ToastContextProvider } from 'context/toast.context';
import React, { Suspense, lazy } from 'react';
import HeroBannerSuspense from 'components/utils/HeroBannerSuspense';
import 'assets/css/common.css';
import DigitalLibraryFileDetails from 'pages/digital-library/digital-library-file-details';
import DigitalLibraryLikedDetails from 'pages/digital-library/digital-library-liked-details';
import DigitalLibraryLiked from 'pages/digital-library/digital-library-liked';
import './font.css';
import { SearchContextProvider } from 'context/search.context';
const Home = lazy(() => import('pages/home'));
const MainLayout = lazy(() => import('components/layout/MainLayout'));
const NewsLayout = lazy(() => import('components/layout/NewsLayout'));
const NewsCategory = lazy(() => import('pages/news/news-category'));
const NewsDetails = lazy(() => import('pages/news/news-details'));
const DigitalLibraryLayout = lazy(() => import('components/layout/DigitalLibraryLayout'));
const DigitalLibrary = lazy(() => import('pages/digital-library/digital-library'));
const DigitalLibraryFolder = lazy(() => import('pages/digital-library/digital-library-folder'));
const DigitalMuseum = lazy(() => import('pages/digital-museum/digital-museum'));

function App() {
  return (
    <>
      <ToastContextProvider>
        <NewShareModalContextProvider>
          <SearchContextProvider>
            <ScrollToTop />
            <Routes>
              <Route
                path='/'
                element={
                  <Suspense fallback={null}>
                    <MainLayout />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<HeroBannerSuspense />}>
                      <Home />
                    </Suspense>
                  }
                />
                {routes.map(
                  ({ path, element }, key) =>
                    element && (
                      <Route key={key} exact path={path} element={<Suspense fallback={null}>{element}</Suspense>} />
                    )
                )}
                <Route
                  element={
                    <Suspense fallback={null}>
                      <NewsLayout />
                    </Suspense>
                  }
                >
                  <Route
                    exact
                    path='/tin-tuc/:chuyen_muc?'
                    element={
                      <Suspense fallback={null}>
                        <NewsCategory />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path='/tin-tuc/:chuyen_muc/:tin_tuc_slug?'
                    element={
                      <Suspense fallback={null}>
                        <NewsDetails />
                      </Suspense>
                    }
                  />
                </Route>
                <Route
                  element={
                    <Suspense fallback={null}>
                      <DigitalLibraryLayout />
                    </Suspense>
                  }
                >
                  <Route
                    exact
                    path='/thu-vien-so'
                    element={
                      <Suspense fallback={null}>
                        <DigitalLibrary />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path='/thu-vien-so/:loai_thu_vien/:folder_id?'
                    element={
                      <Suspense fallback={null}>
                        <DigitalLibraryFolder />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path='/thu-vien-so/:loai_thu_vien/:folder_id/:file_id'
                    element={
                      <Suspense fallback={null}>
                        <DigitalLibraryFileDetails />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path='/thu-vien-so/danh-sach-yeu-thich'
                    element={
                      <Suspense fallback={null}>
                        <DigitalLibraryLiked />
                      </Suspense>
                    }
                  />
                  <Route
                    exact
                    path='/thu-vien-so/danh-sach-yeu-thich/thu-vien-so'
                    element={
                      <Suspense fallback={null}>
                        <DigitalLibraryLikedDetails />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route
                path='/bao-tang-so'
                element={
                  <Suspense fallback={null}>
                    <DigitalMuseum />
                  </Suspense>
                }
              />
            </Routes>
          </SearchContextProvider>
        </NewShareModalContextProvider>
      </ToastContextProvider>
    </>
  );
}

export default App;
