import { ICON_IMAGE_PLACEHOLDER } from 'constant/Icon';

const DigitalMuseumLoading = ({ children, isLoading }) => {
  return (
    <div>
      <div
        className='data-[state=hidden]:hidden data-[state=show]:block animate-loading'
        data-state={isLoading ? 'show' : 'hidden'}
      >
        <div className='animate-pulse'>
          <div className='container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-24 translate-y-[-80px] mb-[-80px]'>
            {Array.from(Array(12), () => {
              return (
                <div className='mb-12'>
                  <div className='gap-x-4 cursor-pointer'>
                    <div className='w-full gap-x-4 h-fit shadow-md rounded-md overflow-hidden'>
                      <div class='inline-block w-full h-fit relative after:block after:pt-[66.5%]'>
                        <div className='absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-gray-400'>
                          <ICON_IMAGE_PLACEHOLDER fill={'#e5e7eb'} />
                        </div>
                      </div>
                      <div className='w-full min-h-[52px] px-4'>
                        <div className='bg-gray-400 rounded-md h-4 w-full mb-2'></div>
                        <div className='flex justify-center'>
                          <div className='bg-gray-400 rounded-md h-4 w-6/12'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className='data-[state=hidden]:hidden data-[state=show]:block animate-loading'
        data-state={isLoading ? 'hidden' : 'show'}
      >
        {children}
      </div>
    </div>
  );
};

export default DigitalMuseumLoading;
