import { useSearchParams } from 'react-router-dom';
import useAxios from 'hooks/useAxios';
import HeroBannerSuspense from 'components/utils/HeroBannerSuspense';
import { Suspense, lazy, useEffect, useState } from 'react';
import DigitalMuseumCard from 'components/digital-museum/DigitalMuseumCard';
import Pagination from 'components/utils/Pagination';
import DigitalMuseumLoading from 'components/layout/loading/DigitalMuseumLoading';
import { useSearchContext } from 'context/search.context';

const HeroBanner = lazy(() => import('components/digital-museum/HeroBanner'));

export default function DigitalMuseum() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debouncedFilterValue } = useSearchContext();
  const [options, setOptions] = useState({
    page: searchParams.get('page') || 1,
    limit: searchParams.get('limit') || 12
  });
  const { data: digitalMuseum, loading: digitalMuseumLoading } = useAxios(`/public/digital-museum`, options, true);

  useEffect(() => {
    const _options = { ...options };
    if (debouncedFilterValue) {
      _options.search = debouncedFilterValue;
    } else {
      delete _options.search;
    }
    setOptions(_options);
  }, [debouncedFilterValue]);

  const handlePageClick = (event) => {
    const newOptions = JSON.parse(
      JSON.stringify({
        ...options,
        page: event.selected + 1
      })
    );
    setOptions(newOptions);
    setSearchParams(newOptions);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className=''>
      <Suspense fallback={<HeroBannerSuspense />}>
        <HeroBanner digitalMuseum={digitalMuseum} loading={digitalMuseumLoading} />
      </Suspense>
      <DigitalMuseumLoading isLoading={digitalMuseumLoading}>
        <div className='container mx-auto translate-y-[-80px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-24 mb-[-80px]'>
          {!!digitalMuseum?.data?.length &&
            digitalMuseum?.data?.map((item) => (
              <div className='mb-12'>
                <Suspense fallback={null}>
                  <DigitalMuseumCard
                    key={item.title}
                    href={item.link}
                    img={item.thumbnail}
                    title={item.name}
                    imageWidth={500}
                    imageHeight={330}
                  />
                </Suspense>
              </div>
            ))}
        </div>
      </DigitalMuseumLoading>
      {!!digitalMuseum?.totalPages && (
        <div className='flex justify-center mb-12'>
          <Pagination
            page={options.page}
            pageCount={digitalMuseum?.totalPages || 1}
            handlePageClick={handlePageClick}
          />
        </div>
      )}
    </div>
  );
}
