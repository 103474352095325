import useDebounce from 'hooks/useDebounce';
import React, { createContext, useContext, useState } from 'react';

const SearchContext = createContext();

export function SearchContextProvider({ children }) {
  const [searchValue, setSearchValue] = useState('');
  const debouncedFilterValue = useDebounce(searchValue, 400);

  return (
    <SearchContext.Provider value={{ searchValue, setSearchValue, debouncedFilterValue }}>
      {children}
    </SearchContext.Provider>
  );
}

export function useSearchContext() {
  const searchContext = useContext(SearchContext);

  if (!searchContext) {
    throw new Error('useSearchContext must be used within a SearchContextProvider');
  }

  return searchContext;
}
