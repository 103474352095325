import { useOutletContext, useParams } from 'react-router-dom';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import DigitalLibraryFolderLoading from 'components/layout/loading/DigitalLibraryFolderLoading';
import axiosDigitalLibraryInstance from 'helpers/axios-digital-library';
import { LOAI_THU_VIEN } from 'data/enum';
import Pagination from 'components/utils/Pagination';

const DigitalLibraryHorizontalCard = lazy(() => import('components/digital-library/DigitalLibraryHorizontalCard'));

export default function DigitalLibraryLikedDetails() {
  const { defaultBreadcrumbs, breadcrumbs, setBreadcrumbs, loadingBreadcrumbs, setLoadingBreadcrumbs } =
    useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [options, setOptions] = useState({
    page: searchParams.get('page') || 1,
    pageSize: searchParams.get('pageSize') || 10
  });
  const [digitalLibraryFilesLiked, setDigitalLibraryFilesLiked] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const fetchData = async () => {
    setLoading(true);
    let response = null;
    const token = localStorage.getItem('accessToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    response = await axiosDigitalLibraryInstance.get(`/digital-library-file/favorite`, {
      ...config,
      params: { page: options.page, limit: options.pageSize }
    });

    setDigitalLibraryFilesLiked(response.data.data);
    setTotalPages(response.data.totalPages || 0);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [options]);

  useEffect(() => {
    setLoadingBreadcrumbs(true);
    setBreadcrumbs([
      ...defaultBreadcrumbs,
      { title: 'Danh sách yêu thích', href: `/thu-vien-so/danh-sach-yeu-thich` },
      { title: 'Thư viện số', href: `/thu-vien-so/danh-sach-yeu-thich/thu-vien-so` }
    ]);
    setLoadingBreadcrumbs(false);
  }, []);

  const handlePageClick = (event) => {
    const newOptions = JSON.parse(
      JSON.stringify({
        ...options,
        page: event.selected + 1
      })
    );
    setOptions(newOptions);
    setSearchParams(newOptions);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleDislike = async (id) => {
    const _digitalLibraryFilesLiked = [...digitalLibraryFilesLiked].filter((item) => item.id !== id);
    setDigitalLibraryFilesLiked(_digitalLibraryFilesLiked);
    const token = localStorage.getItem('accessToken');
    const config = token ? { headers: { Authorization: `Bearer ${token}` } } : {};
    await axiosDigitalLibraryInstance.post(
      `/digital-library-file/favorite`,
      {
        favoriteId: id
      },
      {
        ...config
      }
    );
  };

  return (
    <div>
      <DigitalLibraryFolderLoading isLoading={loading}>
        <div>
          {!!digitalLibraryFilesLiked?.length ? (
            digitalLibraryFilesLiked.map((digitalLibraryFile) => (
              <div className='border-b border-[#C0C0C0] pb-8 mb-8'>
                <Suspense fallback={null}>
                  <DigitalLibraryHorizontalCard
                    id={digitalLibraryFile.id}
                    href={`/thu-vien-so/${
                      digitalLibraryFile.isPublic ? LOAI_THU_VIEN.THU_VIEN_CHUNG : LOAI_THU_VIEN.THU_VIEN_DON_VI
                    }/${digitalLibraryFile.folder.id}/${digitalLibraryFile.id}`}
                    title={digitalLibraryFile.name}
                    updatedAt={digitalLibraryFile.updatedAt}
                    img={digitalLibraryFile.coverPhoto}
                    content={digitalLibraryFile.description}
                    viewCount={digitalLibraryFile?.viewCount || 0}
                    downloadCount={digitalLibraryFile?.downloadCount || 0}
                    likeCount={digitalLibraryFile?.favoriteCount || 0}
                    onDislike={handleDislike}
                    folderName={digitalLibraryFile?.folder?.name || ''}
                  />
                </Suspense>
              </div>
            ))
          ) : (
            <p className='text-center text-[#717171] italic text-sm'>Không có tài liệu</p>
          )}
        </div>
      </DigitalLibraryFolderLoading>
      {!!digitalLibraryFilesLiked?.length && (
        <div className='flex justify-center'>
          <Pagination page={options.page} pageCount={totalPages || 1} handlePageClick={handlePageClick} />
        </div>
      )}
    </div>
  );
}
