import { formatDateVietnamese } from 'helpers/formatDate';
import { useNavigate } from 'react-router-dom';

const { ICON_EYE, ICON_DOWNLOAD, ICON_HEART } = require('constant/Icon');

const DigitalLibraryVerticalCard = ({
  href,
  img,
  title,
  updatedAt,
  imageWidth = 500,
  imageHeight = 667,
  viewCount = 0,
  likeCount = 0,
  downloadCount = 0
}) => {
  const navigate = useNavigate();
  return (
    <div className='box-shadow-primary rounded-lg overflow-hidden'>
      <div
        className='relative p-4 sm:p-6 overflow-hidden'
        style={{
          backgroundImage: `url(${img})`
        }}
      >
        <div className='bg-white absolute w-full h-full opacity-40 top-0 left-0'></div>
        <div className='bg-white absolute w-full h-[60px] sm:h-[100px] left-0 bottom-0'></div>
        <img
          src={`${img}?alt=media&width=${imageWidth}&height=${imageHeight}&target_type=webp`}
          alt='thumbnail'
          className='relative z-10 rounded-lg aspect-[3/4] object-cover box-shadow-primary hover:scale-105 transition-transform duration-300 cursor-pointer'
          onClick={() => navigate(href)}
        />
      </div>
      <div className='px-4 pb-4'>
        <h2
          className='line-clamp-2 min-h-[48px] font-bold mb-2 cursor-pointer hover:text-primary transition-colors duration-100'
          title={title}
          onClick={() => navigate(href)}
        >
          {title}
        </h2>
        <p className='text-[#8C8C8C] mb-2 text-sm'>Ngày cập nhật: {formatDateVietnamese(updatedAt)}</p>
        <div className='flex items-center justify-between text-sm'>
          <div className='flex gap-x-2 2xl:gap-x-4'>
            <div className='flex items-center gap-x-1 2xl:gap-x-2'>
              <ICON_EYE />
              <p className='text-primary font-semibold'>{viewCount}</p>
            </div>
            <div className='flex items-center gap-x-1 2xl:gap-x-2'>
              <ICON_DOWNLOAD />
              <p className='text-primary font-semibold'>{downloadCount}</p>
            </div>
          </div>
          <div className='flex items-center gap-x-1 2xl:gap-x-2'>
            <p className='text-primary font-semibold'>{likeCount}</p>
            <ICON_HEART />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalLibraryVerticalCard;
